<template>
    <div class="container">
      <content-title :nav='nav'></content-title>
      <!-- <el-row>
        <el-row style='margin-bottom:1rem'>
          <label> 手机号 </label>
          <el-input v-model="phone" clearable></el-input>
          <label> 机构 </label>
              <el-input v-model="mechanism_name" clearable></el-input>
              <el-select filterable v-model="mechanism_id" clearable remote :remote-method="searchOrgName"
                @focus="searchOrgName">
                <el-option v-for="(option, index) in mechanism_name_list" :key="index" :value="option.id"
                  :label="option.mechanism_name"></el-option>
              </el-select>
           <label> 支付金额 </label>
          <el-input v-model="amount" clearable></el-input>
          <label> 状态 </label>
            <el-select v-model="status" placeholder="请选择" clearable>
              <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
           <label> 订单类型 </label>
            <el-select v-model="orderType" placeholder="请选择" clearable>
              <el-option
              v-for="item in orders"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
          <el-button type='primary' @click='search'>搜索</el-button>
        </el-row> -->
  
        <el-table :data="payList" :fit="true" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" v-loading="is_loading">
          <el-table-column label="id" prop="id" width="100"></el-table-column>
          <el-table-column label="用户id" prop="content" width="100"></el-table-column>
          <el-table-column label="生成时间" prop="create_time" width="200">
            <!-- <template slot-scope="{row}">
              <div>
                {{timeFormat(row.create_time)}}
              </div>
            </template> -->
          </el-table-column>
          <el-table-column label="转账情况" prop="result">
            <template slot-scope="{row}">
              <div class="">
                {{getcodeInfo(row.result).sub_msg || getcodeInfo(row.result).msg}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="{row}">
              <span
                  @click="look(row)"
                  style="color: #F5666C"
                  >查看详细原因</span
                >
            </template>
          </el-table-column>
          
        </el-table>
        <el-pagination :current-page="currentPage" :total="payTotal" @current-change="changeCurrentPage" background>
        </el-pagination>
  

        <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="50%">
 <p class="res">
  {{currow}}
 </p>

</el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible :false,
        currow:'',
        nav: { firstNav: '财务管理中心', secondNav: '转账错误日志' },
        mechanism_id: '',
        mechanism_name:'',
        mechanism_list: [],
        payList: [],
        status: null,
        is_loading: true,
        refuseReason: false,
        showReason: false,
        refuse_contect: '',
        is_loading: false,
        currentPage: 1,
        payTotal: 0,
        temp: {},
        status:'true',
        phone:'',
        mechanism_name_list:[],
        amount:'',
        nickname:'',
        orderType:'',
      }
    },
    mounted() {
      this.getRefoundList()
    },
    methods: {
      look(row){
        this.dialogVisible = true
        this.currow = row.result
      },
      handleClose(){

      },
      getcodeInfo(str){
          let res =JSON.parse(str)
          return res.alipay_fund_trans_uni_transfer_response

      },
      timeFormat(time){
        let now = new Date(time).getTime() + 1000 * 60 *60 *8
        return new Date(now).format('yyyy-MM-dd hh:mm:ss')
      },
      //数据
      getRefoundList() {
        this.is_loading = true
        let url = '/user/logOperationHistory/queryManagerListPage'
        let params={
            operation:'转账失败',
              pageSize: 10,
              currentPage: this.currentPage,     
            }
        this.$axios
          .get(url, {
            params
          })
          .then((res) => {
            this.payList = res.data.data.rows
            this.payTotal = res.data.data.total
            this.is_loading = false
          })
      },
      search() {
        this.currentPage = 1
        this.getRefoundList()
      },
      changeCurrentPage(v) {
        this.currentPage = v
        this.getRefoundList()
      },
      refuse(row) {
        this.temp = row
        this.refuseReason = true
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            this.refuseReason = false
            this.showReason = false
            this.temp = {}
            done()
          })
          .catch((_) => {})
      },
    }
  }
  </script>
  
  <style lang="less">
  .expand-form{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    .el-form-item{
      width: 40%;
    }
  }
  .rowell{
    display: -webkit-box !important;
        overflow: hidden;

        word-break:break-all ;
        text-overflow: ellipsis;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;  
  }
  .res{
    padding: 20px;
  }
  </style>